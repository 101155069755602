import React, { Component } from 'react';
import axios from 'axios';
import HCaptcha from '@hcaptcha/react-hcaptcha';

import './form.scss';

class ContactForm extends Component {
  constructor(props) {
    super(props);

    this.state = {  
      name: '',
      email: '',
      message: '',
      isSent: false,
      success: false,
      successMsg: '',
      token: ''
    };
  }

  onInputChange = event => {
    this.setState({ [event.target.name]: event.target.value });
  };

  onFormSubmit = async (event) => {
    event.preventDefault();   

    const newEmail = {
      name: this.state.name,
      email: this.state.email,
      message: this.state.message,
      token: this.state.token
    }

    // add logic to send e-mail through contact form here depending on deployment
    try {
      const response = await axios.post('/api/send-message', newEmail);
      this.setState({
        successMsg: 'Thank you, your message has been sent.',
        success: true
      });
    } catch (err) {
      console.log(err);
      this.setState({successMsg: 'There has been an error. Please try again later'});
    }
    
    this.setState({
      name : '',
      email : '',
      message : '',
      isSent: true,
      token: ''
    });
      
  };

  render() {

    const isSent = this.state.isSent;
    const alertType = this.state.success ? 'alert alert-success' : 'alert alert-danger';

    return (          
      <div className='col-12 col-lg-6'>
        {isSent && 
          <div className={alertType} role="alert">
            {this.state.successMsg}
          </div>
        } 
        <form className='form-container' onSubmit={this.onFormSubmit}>
          <div className='form-group'>
            <label className='form-label' htmlFor='name'>
              Your Name (required)
            </label>
            <input
              name='name'
              type='text'
              className='form-control'
              value={this.state.name}
              onChange={this.onInputChange}
              required
            />
          </div>
          <div className='form-group'>
            <label className='form-label' htmlFor='email'>
              Your E-mail (required)
            </label>
            <input
              name='email'
              type='email'
              className='form-control'
              value={this.state.email}
              onChange={this.onInputChange}
              required
            />
          </div>
          <div className='form-group'>
            <label className='form-label' htmlFor='message'>
              Your Message
            </label>
            <textarea
              name='message'
              className='form-control'
              value={this.state.message}
              onChange={this.onInputChange}
              rows='4'
            ></textarea>
          </div>
          <div className="form-group">
            <HCaptcha
              sitekey="92bbdadb-dec5-4c05-aebd-f44f080146a3"
              onVerify={token => this.setState({token})}
              onExpire={e => this.setState({token: ''})}
             />
          </div>
          <div>
            <button
              type='submit'
              className='btn form-button'
              onClick={this.onSubmit}
            >
              Send
            </button>
          </div>
        </form>
      </div>
    );
  }
}

export default ContactForm;
