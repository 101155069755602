import React, { Component } from 'react';

import Logo from '../../Design Assets/Images/traice-logo.png';

import { Link, animateScroll as scroll } from 'react-scroll';

import './nav.scss';

class Navigation extends Component {
  constructor(props) {
    super(props);

    this.state = {
      top: 0
    };
  }

  // Window resize functions

  updateWindowDimensions = () => {
    this.setState({ top: 0 });
  };

  componentDidMount() {
    this.updateWindowDimensions();
    window.addEventListener('resize', this.updateWindowDimensions);
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.updateWindowDimensions);
  }

  render() {
    var stickyStyle = {
      position: '-webkit-sticky',
      position: 'sticky',
      top: this.state.top,
      zIndex: '1020'
    };

    return (
      <div className='sticky-top nav-fixed'>
        <div className='container-fluid nav-container' style={stickyStyle}>
          <nav className='navbar navbar-expand navbar-light nav-navbar'>
            <a className='navbar-brand' href='#'>
              <img className='nav-logo' src={Logo} alt='trAIce logo'></img>
            </a>

            <div className='navbar-nav nav-container-links'>
              <div className='nav-container-links__div'>
                <Link
                  className='nav-item nav-link nav-link-custom'
                  activeClass='active'
                  to='overview'
                  spy={true}
                  smooth={true}
                  offset={-160}
                  duration={500}
                >
                  Overview
                </Link>
              </div>

              <div className='nav-container-links__div'>
                <Link
                  className='nav-item nav-link nav-link-custom'
                  activeClass='active'
                  to='whatisai'
                  spy={true}
                  smooth={true}
                  offset={-160}
                  duration={500}
                >
                  What is AI
                </Link>
              </div>

              <div className='nav-container-links__div'>
                <Link
                  className='nav-item nav-link nav-link-custom'
                  activeClass='active'
                  to='solutions'
                  spy={true}
                  smooth={true}
                  offset={-275}
                  duration={500}
                >
                  Solutions
                </Link>
              </div>

              <div className='nav-container-links__div'>
                <Link
                  className='nav-item nav-link nav-link-custom'
                  activeClass='active'
                  to='contact'
                  spy={true}
                  smooth={true}
                  offset={-331}
                  duration={500}
                >
                  Contact Us
                </Link>
              </div>
            </div>
          </nav>
        </div>
      </div>
    );
  }
}

export default Navigation;
