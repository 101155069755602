import React, { Component } from 'react';

import TraiceVideo from '../../Design Assets/Videos/traice-youtube-video.mp4';

import PlayButton from '../../Design Assets/Images/playbutton.png';

import TraiceVideoImage from '../../Design Assets/Images/traice-youtubevideo-image.png';

import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';

import './overview.scss';

import Fade from 'react-reveal/Fade';

import { Element } from 'react-scroll';

class Overview extends Component {
  constructor(props) {
    super(props);

    this.state = {
      show: false,
      width: 0
    };
  }

  // Modal functions

  handleShow = () => {
    this.setState({ show: true });
  };

  handleClose = () => {
    this.setState({ show: false });
  };

  // Screen resize functions

  updateWindowDimensions = () => {
    this.setState({ width: window.innerWidth });
  };

  componentDidMount() {
    this.updateWindowDimensions();
    window.addEventListener('resize', this.updateWindowDimensions);
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.updateWindowDimensions);
  }

  render() {
    const { show, width } = this.state;

    return (
      <Element id='overview' name='overview'>
        <div className='container-fluid overview-container'>
          <div className='row overview-text-container__row'>
            <div className='col-sm-12 col-lg-8 overview-text-container__column'>
              {width < 768 ? (
                <p className='overview-text'>
                  trAIce empowers your business to monitor, understand, predict
                  and plan using Artificial Intelligence.
                </p>
              ) : (
                <Fade delay={1000}>
                  <p className='overview-text'>
                    trAIce empowers your business to monitor, understand,
                    predict and plan using Artificial Intelligence.
                  </p>
                </Fade>
              )}
              {width < 768 ? (
                <p className='overview-text'>
                  With trAIce you are no longer limited by internal capacity or
                  technical concerns.
                </p>
              ) : (
                <Fade delay={2000}>
                  <p className='overview-text'>
                    With trAIce you are no longer limited by internal capacity
                    or technical concerns.
                  </p>
                </Fade>
              )}
              {width < 768 ? (
                <p className='overview-text overview-text__last'>
                  Our predictive insights will allow you to prioritize
                  investigative efforts to improve productivity and maintain
                  regulatory compliance in a truly effective and cost efficient
                  manner.
                </p>
              ) : (
                <Fade delay={3000}>
                  <p className='overview-text overview-text__last'>
                    Our predictive insights will allow you to prioritize
                    investigative efforts to improve productivity and maintain
                    regulatory compliance in a truly effective and cost
                    efficient manner.
                  </p>
                </Fade>
              )}
            </div>

            <div className='col-sm-12 col-md-6 col-lg-4 overview-video-container'>
              <img
                className='img-fluid d-block overview-video-image'
                src={TraiceVideoImage}
                alt='trAIce overview video image'
              />
              <button
                className='overview-video-playbutton__button'
                onClick={this.handleShow}
              >
                <img
                  src={PlayButton}
                  className='overview-video-playbutton__image'
                  alt='video play button image'
                ></img>
              </button>
              <p className='overview-video__caption'>
                Watch our overview video
              </p>
            </div>
            <Modal
              centered
              show={show}
              size='lg'
              backdrop
              onHide={this.handleClose}
            >
              <video controls autoPlay className='overview-video-modal__video'>
                <source src={TraiceVideo} type='video/mp4'></source>
              </video>
              <Button
                className='overview-video-modal__button'
                onClick={this.handleClose}
              >
                X
              </Button>
            </Modal>
          </div>
        </div>
      </Element>
    );
  }
}

export default Overview;
