import React from 'react';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTwitter, faLinkedinIn } from '@fortawesome/free-brands-svg-icons';

import './footer.scss';

const Footer = () => {
  const year = new Date().getFullYear();
  return (
    <div className='footer-container'>
      <p className='footer-text'>copyright © {year} - Lexington Innovations Inc</p>
      <div className='footer-icons-container'>
        <a
          href='https://twitter.com/lexingtoninnov'
          title='Twitter'
          className='footer-icons-links'
        >
          <div className='footer-icons-div'>
            <FontAwesomeIcon icon={faTwitter} className='footer-icons' />
          </div>
        </a>

        <a
          href='https://www.linkedin.com/company/10364410'
          title='LinkedIn'
          className='footer-icons-links'
        >
          <div className='footer-icons-div'>
            <FontAwesomeIcon icon={faLinkedinIn} className='footer-icons' />
          </div>
        </a>
      </div>
    </div>
  );
};

export default Footer;
