import React from 'react';

import HeroVideo from '../../Design Assets/Videos/hero-video.mp4';

import './hero.scss';

const Hero = () => {
  return (
    <div>
      <div className='hero-backgrounddiv'>
        <video className='hero-video' autoPlay muted loop id='heroVideo'>
          <source src={HeroVideo} type='video/mp4' />>
        </video>
      </div>
    </div>
  );
};

export default Hero;
