import React, { Component, Fragment } from 'react';

import './App.scss';

import Navigation from '../Nav/nav';

import Hero from '../Hero/hero';

import Overview from '../Overview/overview';

import WhatIsAI from '../WhatIsAI/whatisai';

import Solutions from '../Solutions/solutions';

import ContactForm from '../Form/form';

import Map from '../Map/map';

import Footer from '../Footer/footer';

import NavMobile from '../MobileNav/mobilenav';

import { Element } from 'react-scroll';

class App extends Component {
  constructor(props) {
    super(props);
    this.state = { width: 0 };
  }

  updateWindowDimensions = () => {
    this.setState({ width: window.innerWidth });
  };

  componentDidMount() {
    this.updateWindowDimensions();
    window.addEventListener('resize', this.updateWindowDimensions);
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.updateWindowDimensions);
  }

  render() {
    var width = this.state.width;

    return (
      <Fragment>
        {width <= 576 ? (
          <div>
            <NavMobile />
          </div>
        ) : (
          <div>
            <Navigation />
            <Hero />
            <Overview />
            <div className='whatisai-solutions-div__background'>
              <WhatIsAI />
              <Element
                id='solutions'
                name='solutions'
                style={{ paddingTop: '5%' }}
              >
                <Solutions />
              </Element>
            </div>
            <Contact />
            <Footer />
          </div>
        )}
      </Fragment>
    );
  }
}

export class Contact extends Component {
  render() {
    return (
      <Element id='contact' name='contact'>
        <div className='container-fluid contact-container'>
          <div className='row'>
            <ContactForm />
            <div className='col-12 col-lg-6 map-mobile'>
              <Map />
            </div>
          </div>
        </div>
      </Element>
    );
  }
}

export default App;
