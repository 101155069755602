import React, { Component } from 'react';

import GoogleMaps from 'simple-react-google-maps';

import './map.scss';

import * as dotenv from 'dotenv';

dotenv.config();

// Lexington's GoogleMaps API key as provided by Phill
// Should be onscured server-side depending on how/where site is deployed ex. as an environment variable
// Also should be restricted on Google Maps
//const apiKey = 'AIzaSyCQzRAsUITipQlVyuaa7tf40bRwZcFIzL8'; // original updated to be lex key below
const apiKey = process.env.REACT_APP_GOOGLE_MAPS_JS_API_KEY;

class Map extends Component {
  render() {
    return (
      <div className='map-container'>
        <GoogleMaps
          apiKey={apiKey}
          style={{ height: '440px', width: '100%' }}
          zoom={15}
          center={{ lat: 43.650231, lng: -79.383237 }}
          markers={{ lat: 43.650231, lng: -79.383237 }} //optional
        />

        <p className='map-address'>
          120 Adelaide St W, Suite 2500, Toronto, ON, M5H 1T1
        </p>
        <p className='map-phonenumber'>Phone: (416) 319-2586</p>
      </div>
    );
  }
}

export default Map;
