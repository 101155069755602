import React, { Component, Fragment } from 'react';

import SolutionsImage1 from '../../Design Assets/Images/traice-solutions-1-resized.png';

import SolutionsImage2 from '../../Design Assets/Images/traice-solutions-2-resized.png';

import SolutionsImage3 from '../../Design Assets/Images/traice-solutions-3-resized.png';

import './solutions.scss';

import Fade from 'react-reveal/Fade';

import { Element } from 'react-scroll';

class Solutions extends Component {
  constructor(props) {
    super(props);

    this.state = { width: 0 };
  }

  updateWindowDimensions = () => {
    this.setState({ width: window.innerWidth });
  };

  componentDidMount() {
    this.updateWindowDimensions();
    window.addEventListener('resize', this.updateWindowDimensions);
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.updateWindowDimensions);
  }

  render() {
    var width = this.state.width;

    return (
      <Fragment>
        {width < 768 ? (
          <div className='container-fluid solutions-container-mobile'>
            <div className='row solutions-titles-row-mobile'>
              <div className='col-12 solutions-container-row-titlesColumns'>
                <p className='solutions-titles-mobile'>AML MONITORING</p>
              </div>
              <div className='col-12'>
                <img
                  className='img-fluid d-block mx-auto solutions-images-mobile'
                  src={SolutionsImage1}
                ></img>
              </div>
              <div className='col-12'>
                <p className='solutions-captions-mobile solutions-captions-mobile__AI'>
                  Adaptive AI Algorithms get 'smarter' over time
                </p>
              </div>

              <div className='col-12 solutions-container-row-titlesColumns'>
                <p className='solutions-titles-mobile'>FRAUD DETECTION</p>
              </div>
              <div className='col-12'>
                <img
                  className='img-fluid d-block mx-auto solutions-images-mobile'
                  src={SolutionsImage2}
                ></img>
              </div>
              <div className='col-12'>
                <p className='solutions-captions-mobile solution-captions-mobile__Fraud'>
                  Using multiple detection methodologies to identify and
                  prioritize fraud risk
                </p>
              </div>
              <div className='col-12 solutions-container-row-titlesColumns'>
                <p className='solutions-titles-mobile solutions-titles-mobile__Compliance'>
                  COMPLIANCE AND CONTRACT ANALYTICS
                </p>
              </div>
              <div className='col-12'>
                <img
                  className='img-fluid d-block mx-auto solutions-images-mobile'
                  src={SolutionsImage3}
                ></img>
              </div>
              <div className='col-12'>
                <p className='solutions-captions-mobile solution-captions-mobile__Compliance'>
                  Automatically analyse, validate, extract and modify
                  information from contracts
                </p>
              </div>
            </div>
          </div>
        ) : (
          <div className='container-fluid solutions-container'>
            <div className='row solutions-titles-row'>
              <div className='col-3 text-center' style={{ margin: 'auto 0' }}>
                <p className='solutions-titles'>AML MONITORING</p>
              </div>
              <div className='col-3 text-center solutions-titlesColumn_Fraud'>
                <p className='solutions-titles'>FRAUD DETECTION</p>
              </div>
              <div
                className='col-3 text-center class'
                style={{ margin: 'auto 0', paddingBottom: '2%' }}
              >
                <p className='solutions-titles solutions-titlesCompliance'>
                  COMPLIANCE AND CONTRACT ANALYTICS
                </p>
              </div>
            </div>

            <div className='container-fluid solution-images-container'>
              <div className='row solutions-images-row'>
                <div className='col-3'>
                  <img
                    className='img-fluid d-block mx-auto solutions-images'
                    src={SolutionsImage1}
                  ></img>
                </div>
                <div className='col-3 solutions-imagesColumn__Fraud'>
                  <img
                    className='img-fluid d-block mx-auto solutions-images'
                    src={SolutionsImage2}
                  ></img>
                </div>

                <div className='col-3'>
                  <img
                    className='img-fluid d-block mx-auto solutions-images'
                    src={SolutionsImage3}
                  ></img>
                </div>
              </div>
            </div>

            <div className='row solutions-captions-row'>
              <div className='col-3 text-center'>
                <Fade delay={1000}>
                  <p className='solutions-captions'>
                    Adaptive AI Algorithms get 'smarter' over time
                  </p>
                </Fade>
              </div>
              <div className='col-3 text-center solutions-captions-divFraud'>
                <Fade delay={2000}>
                  <p className='solutions-captions'>
                    Using multiple detection methodologies to identify and
                    prioritize fraud risk
                  </p>
                </Fade>
              </div>
              <div className='col-3 text-center'>
                <Fade delay={3000}>
                  <p className='solutions-captions'>
                    Automatically analyse, validate, extract and modify
                    information from contracts
                  </p>
                </Fade>
              </div>
            </div>
          </div>
        )}
      </Fragment>
    );
  }
}

export default Solutions;
