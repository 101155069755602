import React, { Component } from 'react';

import DiagramVideo1 from '../../Design Assets/Videos/WhatIsAI-Algo.mp4';

import DiagramVideo2 from '../../Design Assets/Videos/WhatIsAI-AI.mp4';

import DiagramImage1 from '../../Design Assets/Images/whatisai-algotrans.png';

import DiagramImage2 from '../../Design Assets/Images/whatisai-aitrans.png';

import './whatisai.scss';

class WhatIsAI extends Component {
  constructor(props) {
    super(props);

    this.state = { width: 0 };
  }

  updateWindowDimensions = () => {
    this.setState({ width: window.innerWidth });
  };

  componentDidMount() {
    this.updateWindowDimensions();
    window.addEventListener('resize', this.updateWindowDimensions);
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.updateWindowDimensions);
  }

  render() {
    var width = this.state.width;

    return (
      <div className='whatisai-wrapper'>
        <div className='container-fluid whatisai-container'>
          <div className='row whatisai-container-row'>
            <div
              className='col-sm-12 col-md-6 whatisai-leftcolumn'
              id='whatisai'
            >
              {width < 576 ? (
                <img
                  className='img-fluid d-block whatisai-image whatisai-image__firstMobile'
                  src={DiagramImage1}
                ></img>
              ) : (
                <video
                  className='img-fluid mx-auto d-block whatisai-image whatisai-image__firstMobile'
                  autoPlay
                  playsInline
                  muted
                  loop
                >
                  <source src={DiagramVideo1}></source>
                </video>
              )}
              <div className='whatisai-text-div__left'>
                <p className='whatisai-text whatisai-text__left'>
                  The current methodology combines input with rules, described
                  as an Algorithm. Not efficient when the rules of the problem
                  domain are fluid or are difficult to describe fully.
                </p>
              </div>
            </div>
            <div className='col-sm-12 col-md-6 whatisai-rightcolumn'>
              {width < 576 ? (
                <img
                  className='img-fluid mx-auto d-block whatisai-image'
                  src={DiagramImage2}
                ></img>
              ) : (
                <video
                  className='img-fluid mx-auto d-block whatisai-image'
                  autoPlay
                  playsInline
                  muted
                  loop
                >
                  <source src={DiagramVideo2}></source>
                </video>
              )}

              <div className='whatisai-text-div__right'>
                <p className='whatisai-text whatisai-text__right whatisai-text__last'>
                  Our new method uses both inputs and outputs to come up with
                  rules for a problem domain on its own. Then, those rules can
                  be applied to new inputs to obtain more accurate results.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default WhatIsAI;
