import React, { Component } from 'react';

import ReactDOM from 'react-dom';

import Overview from '../Overview/overview';

import WhatIsAI from '../WhatIsAI/whatisai';

import Solutions from '../Solutions/solutions';

import Footer from '../Footer/footer';

import { Contact } from '../App/App.js';

import Logo from '../../Design Assets/Images/traice-logo.png';

import './mobilenav.scss';

const InitialState = {
  showOverview: false,
  showWhatIsAI: false,
  showSolutions: false,
  showContact: false
};

export default class NavMobile extends Component {
  constructor(props) {
    super(props);

    this.state = {
      ...InitialState
    };
  }

  getOffset = element => {
    var bounding = element.getBoundingClientRect();

    return {
      top: bounding.top
    };
  };

  handleScroll = () => {
    var overview = ReactDOM.findDOMNode(this.refs.overview);

    var whatisai = ReactDOM.findDOMNode(this.refs.whatisai);

    var solutions = ReactDOM.findDOMNode(this.refs.solutions);

    var contact = ReactDOM.findDOMNode(this.refs.contact);

    var offsetOverview = this.getOffset(overview);

    console.log(offsetOverview.top);

    var offsetWhatisai = this.getOffset(whatisai);

    console.log(offsetWhatisai.top);

    var offsetSolutions = this.getOffset(solutions);

    console.log(offsetSolutions.top);

    var offsetContact = this.getOffset(contact);

    console.log(offsetContact.top);

    var windowsScrollTop = window.pageYOffset;

    console.log(windowsScrollTop);

    if (
      windowsScrollTop >= offsetOverview.top &&
      windowsScrollTop < offsetWhatisai.top
    ) {
      this.setState({ showOverview: true });
    } else if (
      windowsScrollTop > offsetWhatisai.top &&
      windowsScrollTop < offsetSolutions.top + 600
    ) {
      this.setState({ ...InitialState });
      this.setState({ showWhatIsAI: true });
    } else if (
      windowsScrollTop > offsetSolutions.top &&
      windowsScrollTop - 1000 < offsetContact.top
    ) {
      this.setState({ ...InitialState });
      this.setState({ showSolutions: true });
    } else if (windowsScrollTop > offsetContact.top + 1500) {
      this.setState({ ...InitialState });
      this.setState({ showContact: true });
    }
  };

  componentDidMount() {
    window.addEventListener('scroll', this.handleScroll);
  }

  componentWillUnmount() {
    window.removeEventListener('scroll', this.handleScroll);
  }

  render() {
    var { showOverview, showWhatIsAI, showSolutions, showContact } = this.state;

    var sectionTitle = () => {
      if (showOverview) {
        return <p className='section-title-text-mobile'>Overview</p>;
      } else if (showWhatIsAI) {
        return <p className='section-title-text-mobile'>What Is AI</p>;
      } else if (showSolutions) {
        return <p className='section-title-text-mobile'>Solutions</p>;
      } else if (showContact) {
        return <p className='section-title-text-mobile'>Contact</p>;
      }
    };

    sectionTitle();

    return (
      <div className='container nav-container-mobile'>
        <nav className='navbar navbar-light nav-navbar-mobile sticky-top'>
          <a className='navbar-brand' href='#'>
            <img className='nav-logo-mobile' src={Logo} alt='trAIce logo'></img>
          </a>
          <div>{sectionTitle()}</div>
        </nav>
        <Overview ref='overview' />
        <WhatIsAI ref='whatisai' />
        <Solutions ref='solutions' />
        <Contact ref='contact' />
        <Footer />
      </div>
    );
  }
}
